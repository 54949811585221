import React, { useState } from 'react';

type GlobalState = {
  step?: string;
  setStep: (step: string) => void;
};

const initialState: GlobalState = {
  step: undefined,
  setStep: () => {
    // Function will be initialized later
  },
};
export const GlobalStateContext = React.createContext<GlobalState>(initialState);

export const GlobalStateProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const [step, setStep] = useState<string>();

  return (
    <GlobalStateContext.Provider
      value={{
        step,
        setStep,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
